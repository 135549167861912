<template>
  <div
    class="index-container container-fluid container-lg"
    :data-id="
      viewport.isGreaterOrEquals('lg') ? DataIdPage.INDEX_PAGE : DataIdPage.INDEX_PAGE_MOBILE
    "
  >
    <div
      class="index-page-row row flex-column flex-column-reverse flex-lg-row align-items-center m-0"
    >
      <div class="content col-12 col-lg-6 col-xl-5 px-24 px-sm-48 py-48 pt-md-24 px-lg-0 pb-lg-0">
        <div class="d-flex flex-column">
          <heading :is-mobile="viewport.isLessThan('lg')" />
        </div>
        <shipment-number-input class="mb-32" @focus="courierSearchAnimPlay" />
      </div>
      <div
        class="col-12 col-lg-6 col-xl-7 d-flex align-items-center justify-content-center pb-0 pt-24 p-lg-32"
      >
        <lottie @complete="courierSearchAnim = $event" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'nuxt/app'
import { defineAsyncComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { type Locales, LogTrackInfoEventType } from '~common/enums'
import { DataIdPage } from '~common/enums/tests.ts'
import { infoTrackEvent, localeHreflangLinks } from '~common/helpers'

const ShipmentNumberInput = defineAsyncComponent(
  () => import('~components/pages/index/ShipmentNumberInput/index.vue'),
)
const Heading = defineAsyncComponent(() => import('~components/pages/index/Heading.vue'))
const Lottie = defineAsyncComponent(() => import('~components/pages/index/Lottie.vue'))

const viewport = useViewport()
const { availableLocales } = useI18n()
const route = useRoute()
const localePath = useLocalePath()
const config = useRuntimeConfig()

const courierSearchAnim = ref()
const courierSearchAnimPlay = () => {
  if (courierSearchAnim.value) {
    courierSearchAnim.value.play()
  }
}

onMounted(() => {
  infoTrackEvent(LogTrackInfoEventType.INDEX_PAGE_SHOW)
})

useHead({
  link: [
    ...localeHreflangLinks(config.public.webUrl, availableLocales as Locales[], route, localePath),
  ],
})
</script>

<style lang="scss" scoped>
@media (min-width: $media-lg) {
  .index-page-row {
    min-height: calc(100dvh - 71px);
  }
}

@media (max-width: $media-md) {
  .content {
    min-height: calc(100dvh - 334px);
  }
}

@media (min-width: $media-md) and (max-width: $media-lg) {
  .content {
    min-height: calc(100dvh - 260px);
  }
}

@media (max-width: $media-lg) {
  .content {
    border-radius: 24px 24px 0 0;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
